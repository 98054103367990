import { Component, ElementRef, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DialogData } from '../../interfaces/interfaces';
import { ButtonCapYeiComponent } from '../button/button.component';
import { getEnvironment } from '../../environment/environment';

@Component({
  selector: 'app-terms-modal',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    ButtonCapYeiComponent,
  ],
  templateUrl: './terms-modal.component.html',
  styleUrl: './terms-modal.component.scss'
})
export class TermsModalComponent implements OnInit, OnDestroy{

  env = getEnvironment();

  constructor(
    private renderer: Renderer2,
    private elRef: ElementRef,
    private router: Router,

    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData | any

  ) {

  }
  dismiss(): void {
    this.dialogRef.close();
  }
  ngAfterViewInit(){
    if (this.data?.classComponent) {
      this.renderer.addClass(this.elRef.nativeElement, this.data?.classComponent);
    }
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
  }

  getTerms(): any {
    switch (this.env.type) {
      case 'the-strongest':
        return `
          1. El abono adquirido es personal e intransferible.<br>
          2. El comprador debe ser mayor de edad y tener capacidad legal para adquirir la sociedad.<br>
          3. El comprador debe pagar el precio acordado por el abono, ya sea al contado o en el plan de cuotas permitido.<br>
          4. En caso de que el abono haya sido adquirida a plan de cuotas, el comprador acepta que en caso de que incumpla cualquiera de los pagos, se bloquea el derecho de todos los beneficios del abono hasta que el comprador cumpla con sus cuotas correspondientes.<br>
          5. El comprador acepta que dicho abono es un aporte voluntario al Club The Strongest.<br>
          6. El comprador no puede utilizar el abono adquirido para fines ilegales o inmorales.<br>
          7. El comprador no puede utilizar el abono adquirido para fines publicitarios sin el consentimiento previo del Club The Strongest.<br>
          8. El Club The Strongest se reserva el derecho de revocar el abono adquirido si el comprador incumple cualquiera de los términos y condiciones establecidos.<br>
        `;
      case 'wilstermann':
        return `
          1. El abono adquirido es personal e intransferible.<br>
          2. El comprador debe ser mayor de edad y tener capacidad legal para adquirir la sociedad.<br>
          3. El comprador debe pagar el precio acordado por el abono, ya sea al contado o en el plan de cuotas permitido.<br>
          4. En caso de que el abono haya sido adquirida a plan de cuotas, el comprador acepta que en caso de que incumpla cualquiera de los pagos, se bloquea el derecho de todos los beneficios del abono hasta que el comprador cumpla con sus cuotas correspondientes.<br>
          5. El comprador acepta que dicho abono es un aporte voluntario al Club Wilstermann.<br>
          6. El comprador no puede utilizar el abono adquirido para fines ilegales o inmorales.<br>
          7. El comprador no puede utilizar el abono adquirido para fines publicitarios sin el consentimiento previo del Club Wilstermann.<br>
          8. El Club Wilstermann se reserva el derecho de revocar el abono adquirido si el comprador incumple cualquiera de los términos y condiciones establecidos.<br>
        `;
      case 'club-bolivar':
        return `
          1. El abono adquirido es personal e intransferible.<br>
          2. El comprador debe ser mayor de edad y tener capacidad legal para adquirir la sociedad.<br>
          3. El comprador debe pagar el precio acordado por el abono, ya sea al contado o en el plan de cuotas permitido.<br>
          4. En caso de que el abono haya sido adquirida a plan de cuotas, el comprador acepta que en caso de que incumpla cualquiera de los pagos, se bloquea el derecho de todos los beneficios del abono hasta que el comprador cumpla con sus cuotas correspondientes.<br>
          5. El comprador acepta que dicho abono es un aporte voluntario al Club Bolívar.<br>
          6. El comprador no puede utilizar el abono adquirido para fines ilegales o inmorales.<br>
          7. El comprador no puede utilizar el abono adquirido para fines publicitarios sin el consentimiento previo del Club Bolívar.<br>
          8. El Club Bolívar se reserva el derecho de revocar el abono adquirido si el comprador incumple cualquiera de los términos y condiciones establecidos.<br>
        `;
      case 'fexco':
        return `
          El adquiriente de la presente entrada se hace total responsable por la información y claves de acceso que contenga dicha entrada, ya que dicha información será entregada y/o enviada únicamente al adquiriente de dicha entrada. Al ser una entrada digital, la misma puede ser utilizada para ingresar al evento en formato físico impreso o en formato digital mediante un dispositivo electrónico, por lo que la persona que presente dichas claves de acceso en el ingreso al evento podrá ingresar siempre y cuando la misma no haya ingresado previamente al mismo. En caso de que dichas claves de acceso caiga en manos de terceros el único responsable será el adquiriente de la entrada.<br>
          La ejecución en la fecha, día y hora del evento para el cual es válida la presente entrada es de plena y única responsabilidad de la empresa productora del evento y en ningún caso de Todotix SRL. Los datos de la empresa o persona responsable del evento para cual la presente entrada es válida serán impresas en la parte anterior de esta entrada. Todotix SRL se libra de cualquier daño, lesión y/o muerte causada directa o indirectamente por la participación o asistencia al evento para la cual la presente entrada es válida. No se aceptan cambios. No se aceptan devoluciones. No se otorgan reembolsos. Entradas perdidas no serán reemplazadas ni reembolsadas. La devolución de dinero por la cancelación o no realización del evento será responsabilidad del organizador de eventos. Condiciones y términos sujetos escribir a legal@todotix.com. No será permitido el ingreso al evento con pirotecnia, grabadores, filmadoras, cámaras de fotografía y de video, ni cualquier elemento similar a los mencionados a criterio del Organizador, pudiendo los mismos ser retirados del lugar y destruido su contenido. El Organizador podrá solicitar que se apague todo equipo de radio llamada o teléfono celular antes del acceso al evento. El adquiriente de la presente entrada es responsable de revisar, aceptar y confirmar los términos y condiciones de su compra previo a realizarla. La edad mínima para la cual un niño o niña debe pagar la entrada será determinado por el organizador del evento. Todas las ventas son finales.
        `;
    }
  }
}
